import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/main",
    name: "Main",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/main/index",
        name: "Index",
        component: () => import("../views/Index.vue"),
      },
      {
        path: "/system/user",
        name: "User",
        component: () => import("../views/system/User.vue"),
      },
      {
        path: "/system/role",
        name: "Role",
        component: () => import("../views/system/Role.vue"),
      },
      {
        path: "/system/dict",
        name: "Dict",
        component: () => import("../views/system/Dict.vue"),
      },
      {
        path: "/system/log",
        name: "Log",
        component: () => import("../views/system/Log.vue"),
      },
      {
        path: "/landing/group",
        name: "LandingGroup",
        component: () => import("../views/Landing/Group.vue"),
      },
      {
        path: "/landing/list",
        name: "LandingList",
        component: () => import("../views/Landing/List.vue"),
      },
      {
        path: "/wechat/group",
        name: "WechatGroup",
        component: () => import("../views/wechat/group.vue"),
      },
      {
        path: "/wechat/list",
        name: "WechatList",
        component: () => import("../views/wechat/list.vue"),
      },
      //数据统计
      {
        path: "/statistics/detail",
        name: "StatisticsDetail",
        component: () => import("../views/statistics/Detail.vue"),
      },
      // 模板列表
      {
        path: "/template/manage",
        name: "TemplateManage",
        component: () => import("../views/template/Manage.vue"),
      },
      // {
      //   path: "/template/edit",
      //   name: "TemplateEdit",
      //   component: () => import("../views/template/Edit.vue"),
      // },
    ],
  },
  {
    path: "/edit/:id?",
    name: "Edit",
    component: () => import("../views/template/Edit.vue"),
  },
  {
    path: "/template",
    name: "Template",
    component: () => import("../views/template/Template.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to);
  if (to.name == "Template") {
    next();
  } else if (to.name !== "Login" && !store.state.userAuth) {
    next({
      name: "Login",
    });
  } else if (!to.name) {
    next({
      name: "landingList",
    });
  } else {
    next();
  }
});

export default router;
