import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "../store";

Vue.use(VueAxios, axios);

// 公共请求路径
axios.defaults.baseURL = "/";

// 超时时间
axios.defaults.timeout = 30000;

// 添加请求拦截器
axios.interceptors.request.use(
  function (request) {
    if (store.state.userAuth) {
      request.headers.Authorization = "Bearer " + store.state.userAuth.token;
    }
    return request;
  },
  function (error) {
    Vue.prototype.$message({
      message: "网络连接超时",
      type: "error",
      duration: 1000,
    });
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    let code = response.data.code;
    let msg = response.data.msg;
    if (code === 401) {
      Vue.prototype.$message({
        message: msg ? msg : "用户未登录或会话超时，请重新登录",
        type: "warning",
        duration: 1000,
        onClose: () => {
          location.href = "/";
        },
      });
    } else if (code === 403) {
      Vue.prototype.$message({
        message: msg ? msg : "无访问权限",
        type: "warning",
        duration: 1000,
      });
    } else if (code === 400 || code === 500) {
      Vue.prototype.$message({
        message: msg ? msg : "请求失败",
        type: "error",
        duration: 1000,
      });
    }
    return response;
  },
  function (error) {
    let status = error.response.status;
    let msg = error.response.data.msg;
    if (status === 401) {
      Vue.prototype.$message({
        message: msg ? msg : "用户未登录或会话超时，请重新登录",
        type: "warning",
        duration: 1000,
        onClose: () => {
          location.href = "/";
        },
      });
    } else if (status === 403) {
      Vue.prototype.$message({
        message: msg ? msg : "无访问权限",
        type: "warning",
        duration: 1000,
      });
    } else if (status === 400 || status === 500) {
      Vue.prototype.$message({
        message: msg ? msg : "请求失败",
        type: "error",
        duration: 1000,
      });
    } else {
      Vue.prototype.$message({
        message: "网络连接超时",
        type: "error",
        duration: 1000,
      });
    }
    return Promise.reject(error);
  }
);
