import Vue from "vue";
import store from "../store";

//密码加密
Vue.prototype.$encodePassword = (password) => {
  let encodePassword = "";
  if (password != null && password !== "") {
    for (let i = 0, len = password.length; i < len; i++) {
      encodePassword = encodePassword + (password.charCodeAt(i) ^ 0x01) + "O";
    }
    encodePassword = encodePassword.substr(0, encodePassword.length - 1);
  }
  return encodePassword;
};

//密码解密
Vue.prototype.$decodePassword = (password) => {
  let decodePassword = "";
  if (password != null && password !== "") {
    let arrData = password.split("O");
    for (let i = 0, len = arrData.length; i < len; i++) {
      decodePassword = decodePassword + String.fromCodePoint(arrData[i] ^ 0x01);
    }
  }
  return decodePassword;
};

//权限认证
Vue.prototype.$auth = (code) => {
  let userAuth = store.state.userAuth;
  if (userAuth && userAuth.roleId !== 1) {
    let privileges = userAuth.privileges;
    return privileges && privileges.indexOf(code) >= 0;
  }
  return true;
};

//权限数据
Vue.prototype.$authData = {
  system: {
    //查询用户权限
    userGet: 100,
    //添加用户权限
    userAdd: 101,
    //编辑用户权限
    userEdit: 102,
    //编辑用户密码权限
    userEditPassword: 103,
    //删除用户权限
    userDelete: 104,
    //查询角色权限
    roleGet: 200,
    //添加角色权限
    roleAdd: 201,
    //编辑角色权限
    roleEdit: 202,
    //删除角色权限
    roleDelete: 203,
    //查询字典权限
    dictGet: 300,
    //添加字典权限
    dictAdd: 301,
    //编辑字典权限
    dictEdit: 302,
    //查询日志权限
    logGet: 400,
  },
};
